.button {
    font-size: 17px !important;
    font-weight: 400 !important;
    cursor: pointer !important;
    text-decoration: none !important;
    border: none !important;
    -webkit-transition: all .3s ease-in-out !important;
    -o-transition: all .3s ease-in-out !important;
    transition: all .3s ease-in-out !important;
    background-color: #0089d0 !important;
    color: #FFF !important;
    border-radius: 2px !important;
    height: 50px !important;
    line-height: 50px;
    padding-left: 18px !important;
    padding-right: 18px !important;
}

.button:hover {
    background-color: #333 !important;
}

.button_small:hover {
    background-color: #333 !important;
}
